<template>
  <div class="tablePrint">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.write_conclusions") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple text-center">
            <div class>
              {{ $t("message.patient_0") }}:
              <b style="text-transform: uppercase"></b>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                v-if="activeName == 'second'"
                :loading="waitingPrint"
                type="success"
                @click="
                  print('hospitalizationConclusions/print?id=' + old_form.id)
                "
                >{{ $t("message.printing") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="modal-body editor-content">
          <el-steps :active="active" finish-status="success" simple>
            <el-step
              :disabled="true"
              @click.native="tabTrigger(1, 'first')"
              :title="$t('message.write_conclusions')"
            ></el-step>
            <el-step
              @click.native="tabTrigger(2, 'second')"
              title="Распечатать"
            ></el-step>
          </el-steps>
          <el-tabs v-model="activeName">
            <el-tab-pane name="first">
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="d-flex print_title_list">
                    <div class="ttitle_Text">
                      {{ selectedTemplate ? selectedTemplate.name : "" }}
                    </div>
                    <div class="d-flex">
                      <el-date-picker
                        v-model="form.date_time"
                        :format="'yyyy-MM-dd HH:mm'"
                        :value-format="'yyyy-MM-dd HH:mm'"
                        type="datetime"
                        style="width: 100% !important; display: inline-block"
                        :placeholder="$t('message.select_time')"
                        class="mr-3"
                      ></el-date-picker>
                      <el-button
                        size="small"
                        icon="el-icon-delete"
                        style="height: 38px"
                        type="danger"
                        @click="clear()"
                        >{{ $t("message.clear") }}</el-button
                      >
                      <el-button
                        style="height: 38px"
                        type="primary"
                        :disabled="
                          form.content == '' || loadingButton ? true : false
                        "
                        @click="submit"
                      >
                        {{ $t("message.save_and_go") }}
                        <i class="el-icon-arrow-right el-icon-right"></i>
                      </el-button>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8" v-loading="loadingTemplates">
                  <div class="boxshii xh_scrol">
                    <el-form
                      ref="form"
                      status-icon
                      :model="form"
                      :rules="rules"
                    >
                      <el-form-item prop="service_id">
                        <el-select
                          clearable
                          @change="chanseService(form.service_id)"
                          v-model="form.service_id"
                          style="width: 100%"
                          :placeholder="$t('message.services')"
                        >
                          <el-option
                            v-for="(item, index) in patient_services"
                            :key="index"
                            :label="item.name"
                            :value="item.service_id"
                            style="
                              width: 100% !important;
                              display: inline-block;
                            "
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>

                    <el-input
                      clearable
                      :placeholder="$t('message.search_template')"
                      v-model="filterText"
                    ></el-input>
                    <div class="favorites mt-1">
                      <el-tag
                        style="cursor: pointer"
                        @click="selected(item)"
                        type="success"
                        closable
                        @close="addFavorites(item.id)"
                        class="ml-1 mt-1"
                        v-for="(item, index) in favorites"
                        :key="index"
                        >{{ item.name }}</el-tag
                      >
                    </div>
                    <el-tree
                      class="mt-2 filter-tree"
                      :data="data"
                      node-key="id"
                      @node-click="selected"
                      :filter-node-method="filterNode"
                      ref="tree"
                    >
                      <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                      >
                        <span class="maxWidht">
                          {{ data.name }}
                          <span v-if="data.code" style="color: blue"
                            >( {{ data.code }} )</span
                          >
                        </span>
                        <span
                          class="tree-meta-price"
                          v-if="data.children.length == 0"
                        >
                          <el-button
                            v-if="!data.favorite"
                            circle
                            @click="addFavorites(data.id)"
                            style="border: none; background: none"
                            icon="el-icon-star-off"
                          ></el-button>
                          <el-button
                            v-if="data.favorite"
                            circle
                            @click="addFavorites(data.id)"
                            style="border: none; background: none"
                            icon="el-icon-check"
                          ></el-button>
                        </span>
                      </span>
                    </el-tree>
                  </div>
                </el-col>
                <el-col :span="16">
                  <!-- <el-card class="mt-2 elCARD" shadow="always" style="    width: 220mm;padding: 20px; margin: auto;     min-height: 290mm;" v-if="form.content">
                     <div  v-html="form.content"></div>
                     
                  </el-card> -->
                  <div class="Editor_widht">
                    <el-card shadow="always">
                      <Tinymce
                        :id="tinymceId"
                        ref="editor"
                        class="naw_Editor"
                        v-model="form.content"
                        :height="800"
                      />
                    </el-card>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
            <el-tab-pane name="second">
              <el-card
                class="mt-4"
                shadow="always"
                style="width: 1000px; margin: 0 auto"
              >
                <div class="content-table" v-html="form.content"></div>
              </el-card>
            </el-tab-pane>
            <!-- ****************** end el-tab-pane  ******************-->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import print from "@/utils/mixins/print";
import Tinymce from "@/components/Tinymce";
import drawerChild from "@/utils/mixins/drawer-child";

export default {
  mixins: [print, drawerChild],
  components: {
    Tinymce,
  },
  data() {
    return {
      reopenUpdate: false,
      loadingData: false,
      tinymceId: "create_conslusion_tiny",
      fileList: [],
      favorites: [],
      loadingButton: false,
      active: 0,
      activeName: "first",
      template: "",
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      templates: [],
      loadingTemplates: false,
      nextButton: true,
      changeTemplateButton: false,
      selectedTemplate: {},
      old_form: "",
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      handler: function () {},
    },
  },
  computed: {
    ...mapGetters({
      patient_services: "hospitalizationServices/patient_services",
      tempList: "formTemplate/all",
      rules: "conclusions/rules",
      model: "conclusions/model",
      columns: "conclusions/columns",
      user_id: "auth/id",
      lastHistory: "dailyTreatments/lastHistory",
    }),
  },
  async mounted() {
    if (this.tempList && this.tempList.length === 0) {
      this.loadTemps();
    }
    await this.categoryDocumentation();
    await this.MyFavorites();
    this.form.date_time = new Date();
  },
  methods: {
    ...mapActions({
      loadTemps: "formTemplate/list",
      doctorTemplate: "formTemplate/doctorTemplate",
      doctorTemplates: "formTemplate/doctorTemplates",
      categoryDocumentationList: "categoryDocumentation/index",
      save: "hospitalizationConclusions/store",
      getServices: "hospitalizationServices/getservices",
    }),
    categoryDocumentation() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {});
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    submit(close = true) {
      this.form.patient_history_id = this.lastHistory.id;

      var formData = new FormData();
      for (var key in this.form) {
        if (this.form[key] != null) {
          formData.append(key, this.form[key]);
        } else {
          formData.append(key, "");
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.old_form = res.data.patient_conculation;
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.activeName = "second";
              this.active++;
              if (
                this.$parent &&
                this.$parent.$parent &&
                _.isFunction(this.$parent.$parent.listChanged)
              ) {
                this.$parent.$parent.listChanged();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    tabTrigger(event, tab_value) {
      if (this.active >= event) {
        this.active = event - 1;
        this.activeName = tab_value;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        name: "",
        service_id: null,
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false,
        date_time: new Date(),
      };
      this.old_form = "";
      this.active = 0;
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.nextButton = true;
      this.close();
    },
    NextTab() {
      this.activeName = "second";
      this.active++;
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    stringToHTML(str) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(str, "text/html");
      return doc.body;
    },
    selected(event) {
      if (event.category_id) {
        let tm = _.find(this.tempList, function (item) {
          return item.id == event.id;
        });
        if (tm) {
          this.selectedTemplate = tm;
          this.form.form_template_id = tm.id;
          this.form.content = this.form.content + "<br>" + tm.content;
        }
      } else {
        this.selectedTemplate = {};
        this.form.form_template_id = null;
        this.form.content = "";
      }
      if (this.form.content != "") this.nextButton = false;
      else this.nextButton = true;
      this.setToTinyContent();
    },
    addFavorites(id) {
      this.doctorTemplate({ template_id: id })
        .then((res) => {
          this.MyFavorites();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    MyFavorites() {
      this.doctorTemplates()
        .then((res) => {
          this.favorites = res.data;
          this.categoryDocumentation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.form.file = event.target.files[0];
      }
    },
    afterOpened() {
      if (this.lastHistory) {
        this.loadingData = true;
        this.getServices(this.lastHistory.id)
          .then(async (res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
          });
      }
    },
    afterClosed() {
      this.form.content = "";
      this.setToTinyContent();
    },
    clear() {
      this.form.content = "";
      this.setToTinyContent();
    },
    chanseService(id) {
      this.form.service_id = id;
    },
    setToTinyContent() {
      window.tinymce.get(this.tinymceId).setContent(this.form.content || "");
    },
  },
};
</script>
<style lang="scss">
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  height: 600px !important;
}
.documentation_content .el-card.is-always-shadow {
  min-height: 700px;
}
.editor-content .content-table {
  max-height: 636px;
  overflow: scroll;
}
.editor-content .el-step__title.is-success {
  cursor: pointer;
}
.editor-content .el-step__title.is-process,
.el-step__title.is-wait {
  cursor: no-drop;
}
// .modal-body .el-tabs__header.is-top{
//     // margin:0
// }
.custom-tree-node {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.tree-meta-price {
  display: block;
  padding-right: 50px;
  font-weight: bold;
}
.favorites > .el-tag.el-tag--success.el-tag--light > .el-icon-close:before,
.favorites > .el-icon-close:before {
  content: none !important;
}
</style>
<style lang="scss">
.tablePrint {
  .el-tabs__nav-wrap.is-top {
    display: none;
  }
  .boxshii {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // margin-top: 46px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 7px;
    border: 1px solid #ebeef5;
  }
}
.print_title_list {
  justify-content: space-between;
}
.Editor_widht .el-card__body,
.elCARD .el-card__body {
  padding: 0px;
}
.xh_scrol {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.xh_scrol::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.xh_scrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
.xh_scrol::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}
</style>